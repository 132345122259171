<template>
  <!-- Header -->
  <div class="flex flex-nowrap text-teal">
    <div class="flex justify-center flex-1">
      <p class="text-center text-orange text-4xl font-bold p-6">
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>
